import styled from "styled-components"

import { colorsV2 } from "src/ui/colors"

type TProgressBarColor = "primary" | "good" | "info" | "error" | "warning"

export function LinearProgressBar({
  segments,
  showRoundedBorders = true,
  height = "M",
}: {
  segments: { value: number; type?: TProgressBarColor }[]
  showRoundedBorders?: boolean
  height?: "S" | "M" | "L"
}) {
  return (
    <Box
      $background={colorsV2.neutralDark}
      $showRoundedBorders={showRoundedBorders}
      $height={height}
    >
      {segments.map((segment, index) => {
        const segmentColor = getColor(segment.type)

        return (
          <ProgressSegment
            key={index}
            $progress={segment.value}
            $color={segmentColor}
          />
        )
      })}
    </Box>
  )
}

function getColor(progress?: TProgressBarColor) {
  switch (progress) {
    case "info":
      return colorsV2.systemInfo

    case "error":
      return colorsV2.systemError

    case "good":
      return colorsV2.systemGood

    case "warning":
      return colorsV2.systemWarning

    case "primary":
    default:
      return colorsV2.primary
  }
}

const ProgressSegment = styled.div<{ $progress: number; $color: string }>`
  height: inherit;
  width: ${(props) => props.$progress || 0}%;
  background: ${(props) => props.$color};
  transition: width 0.4s ease-out;

  &:first-child {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  &:last-child {
    ${(props) => props.$progress === 100 && ` border-top-right-radius: 20px;`}
    ${(props) =>
      props.$progress === 100 && ` border-bottom-right-radius: 20px;`}
  }
`

const Box = styled.div<{
  $background: string
  $showRoundedBorders: boolean
  $height: "S" | "M" | "L"
}>`
  display: flex;
  background-color: ${(props) => props.$background};
  width: 100%;
  height: ${(props) => {
    switch (props.$height) {
      case "S":
        return "4px"
      case "M":
        return "8px"
      case "L":
        return "12px"
      default:
        return "8px"
    }
  }};

  ${(props) => props.$showRoundedBorders && `border-radius: 20px;`}

  ${ProgressSegment} {
    ${(props) => !props.$showRoundedBorders && `border-radius: unset;`}
  }
`
